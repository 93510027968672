import React from "react";
import styles from "./terms-of-service.module.css";
import Box from "@mui/material/Box";

class TermsOfService extends React.Component {
  render() {
    document.body.style.background = "#ffffff";
    return (
      <Box sx={{ p: { xs: 1, sm: 6 } }}>
        <div className={styles.title}>Terms of Service</div>
        <div className={styles.main}>
          <h3 dir="ltr">1. Terms</h3>
          <p dir="ltr">
            By accessing our app, FitTracker, you are agreeing to be bound by
            these terms of service, all applicable laws and regulations, and
            agree that you are responsible for compliance with any applicable
            local laws. If you do not agree with any of these terms, you are
            prohibited from using or accessing FitTracker. The materials
            contained in FitTracker are protected by applicable copyright and
            trademark law.
          </p>
          <h3 dir="ltr">2. Use License</h3>
          <ol>
            <li dir="ltr">
              <p dir="ltr">
                Permission is granted to temporarily download one copy of
                FitTracker per device for personal, non-commercial transitory
                viewing only. This is the grant of a license, not a transfer of
                title, and under this license you may not:
              </p>
            </li>
            <ol>
              <li dir="ltr">
                <p dir="ltr">modify or copy the materials;</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  attempt to decompile or reverse engineer any software
                  contained in FitTracker;
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  remove any copyright or other proprietary notations from the
                  materials; or
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  transfer the materials to another person or "mirror" the
                  materials on any other server.
                </p>
              </li>
            </ol>
            <li dir="ltr">
              <p dir="ltr">
                This license shall automatically terminate if you violate any of
                these restrictions and may be terminated by FitTracker at any
                time. Upon terminating your viewing of these materials or upon
                the termination of this license, you must destroy any downloaded
                materials in your possession whether in electronic or printed
                format.
              </p>
            </li>
          </ol>
          <h3 dir="ltr">3. Disclaimer</h3>
          <ol>
            <li dir="ltr">
              <p dir="ltr">
                The materials within FitTracker are provided on an 'as is'
                basis. FitTracker makes no warranties, expressed or implied, and
                hereby disclaims and negates all other warranties including,
                without limitation, implied warranties or conditions of
                merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of
                rights.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                Further, FitTracker does not warrant or make any representations
                concerning the accuracy, likely results, or reliability of the
                use of the materials on its website or otherwise relating to
                such materials or on any sites linked to FitTracker.
              </p>
            </li>
          </ol>
          <h3 dir="ltr">4. Limitations</h3>
          <p dir="ltr">
            In no event shall FitTracker or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption) arising out of the use or
            inability to use FitTracker, even if FitTracker or a FitTracker
            authorized representative has been notified orally or in writing of
            the possibility of such damage. Because some jurisdictions do not
            allow limitations on implied warranties, or limitations of liability
            for consequential or incidental damages, these limitations may not
            apply to you.
          </p>
          <h3 dir="ltr">5. Accuracy of materials</h3>
          <p dir="ltr">
            The materials appearing in FitTracker could include technical,
            typographical, or photographic errors. FitTracker does not warrant
            that any of the materials on FitTracker are accurate, complete or
            current. FitTracker may make changes to the materials contained in
            FitTracker at any time without notice. However FitTracker does not
            make any commitment to update the materials.
          </p>
          <h3 dir="ltr">6. Links</h3>
          <p dir="ltr">
            FitTracker has not reviewed all of the sites linked to its app and
            is not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by FitTracker of
            the site. Use of any such linked website is at the user's own risk.
          </p>
          <h3 dir="ltr">7. Modifications</h3>
          <p dir="ltr">
            FitTracker may revise these terms of service for its app at any time
            without notice. By using FitTracker you are agreeing to be bound by
            the then current version of these terms of service.
          </p>
          <h3 dir="ltr">8. Governing Law</h3>
          <p dir="ltr">
            These terms and conditions are governed by and construed in
            accordance with the laws of the United States and you irrevocably
            submit to the exclusive jurisdiction of the courts in that State or
            location.
          </p>
        </div>
      </Box>
    );
  }
}

export default TermsOfService;
