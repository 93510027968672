import React from "react";
import styles from "../terms-of-service/terms-of-service.module.css";
import Box from "@mui/material/Box";

class PrivacyPolicy extends React.Component {
  render() {
    document.body.style.background = "#ffffff";
    return (
      <Box sx={{ p: { xs: 1, sm: 6 } }}>
        <div className={styles.title}>Privacy Policy</div>
        <div className={styles.main}>
          <p dir="ltr">
            Protecting your privacy is important. This document explains how
            FitTracker deals with personal identifiable information (PII).
          </p>
          <p dir="ltr">
            Generally, the apps can be used without signing up. We automatically
            collect certain information when you visit, use or navigate the
            apps. This information does not reveal your specific identity (like
            your name or contact information) but may include device and usage
            information, such as your IP address, browser and device
            characteristics, operating system, language preferences, referring
            URLs, device name, country, location, information about how and when
            you use our apps and other technical information. This information
            is primarily needed for our internal analytics, advertising, and
            reporting purposes.
          </p>
          <p dir="ltr">
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>
          <p dir="ltr">The information we collect includes:</p>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                <b>
                  <i>Log and Usage Data.</i>
                </b>{" "}
                Log and usage data is service-related, diagnostic, usage and
                performance information our servers automatically collect when
                you access or use our apps and which we record in log files.
                Depending on how you interact with us, this log data may include
                your IP address, device information, browser type and settings
                and information about your activity in the apps (such as the
                date/time stamps associated with your usage, pages and files
                viewed, searches and other actions you take such as which
                features you use), device event information (such as system
                activity, error reports (sometimes called 'crash dumps') and
                hardware settings).
              </p>
            </li>
          </ul>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                <b>
                  <i>Device Data.</i>
                </b>{" "}
                We collect device data such as information about your computer,
                phone, tablet or other device you use to access the apps.
                Depending on the device used, this device data may include
                information such as your IP address (or proxy server), device
                and application identification numbers, location, browser type,
                hardware model Internet service provider and/or mobile carrier,
                operating system and system configuration information. If you
                are using our apps, we may also collect information about the
                phone network associated with your mobile device, your mobile
                device’s operating system or platform, the type of mobile device
                you use, your mobile device’s unique device ID and information
                about the features of our apps you accessed.
              </p>
            </li>
          </ul>
          <p dir="ltr">We use the information we collect or receive:</p>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                <b>
                  <i>Analytics and reporting purposes.</i>
                </b>{" "}
                We may use your information for other business purposes, such as
                data analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and
                improve our apps, products, marketing and your experience. We
                may use and store this information in aggregated and anonymized
                form so that it is not associated with individual end users and
                does not include personal information. We will not use
                identifiable personal information without your consent.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>
                  <i> Deliver targeted advertising to you.</i>
                </b>{" "}
                We may use your information to develop and display personalized
                content and advertising (and work with third parties who do so)
                tailored to your interests and/or location and to measure its
                effectiveness.
              </p>
            </li>
          </ul>
          <p dir="ltr">
            The apps provide ads using AdMob, which wants to show ads that are
            relevant to you. Users from the European Economic Area (EEA) have
            the option to agree to see ads tailored for them, or to disagree.
            Consent can be changed at any time in the app settings.
          </p>
          <p dir="ltr">
            If you have any other question or concerns, please email contact@appanysoftware.com
          </p>
        </div>
      </Box>
    );
  }
}

export default PrivacyPolicy;
