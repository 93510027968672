import React from "react";
import styles from "./home.module.css";
import mainLogo from "../resources/AppIcon.png";
import appstoreDownloadLogo from "../resources/download-appstore.png";

class Home extends React.Component {
  render() {
    const footer = {
      height: "75px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
    };
    return (
      <div className="body">
        <img
          alt="FitTracker Main Logo"
          className={styles.image}
          src={mainLogo}
        />
        <div className={styles.title}>FitTracker</div>
        <div className={styles.subtitle}>Your workout tracking app.</div>

        <a rel="noopener noreferrer" href="https://apple.co/4hpo1MR">
          <img
            alt="Download on the app store"
            className={styles.imageDownload}
            src={appstoreDownloadLogo}
          />
        </a>

        <div style={footer}>
          <a rel="noopener noreferrer" href="https://www.appanysoftware.com">
            <div className={styles.footer}>
                Appany Software LLC
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default Home;
