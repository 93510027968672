import "./App.css";
import Home from "./home/home.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TermsOfService from "./terms-of-service/terms-of-service";
import PrivacyPolicy from "./privacy-policy/privacy-policy";
import Support from "./support/support.js";
import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDYq2QY3wO-LogzMUoQK9sikDDEzM_Ng50",
  authDomain: "fittracker-8a3ec.firebaseapp.com",
  databaseURL: "https://fittracker-8a3ec.firebaseio.com",
  projectId: "fittracker-8a3ec",
  storageBucket: "fittracker-8a3ec.appspot.com",
  messagingSenderId: "905335059902",
  appId: "1:905335059902:web:1c458e38e5f83c6c3175ef",
  measurementId: "G-ZY21NGXZ18"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const drawerWidth = 240;
const navItems = [
  { title: "Home", route: "/" },
  { title: "Terms of Service", route: "/terms-of-service" },
  { title: "Privacy Policy", route: "/privacy-policy" },
  { title: "Support", route: "/support" },
];

function App(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    analytics.logEvent("Drawer Opened");
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton href={item.route} sx={{ textAlign: "center" }}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <AppBar component="nav" style={{ background: "#810001" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Toolbar />
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path="/terms-of-service"
              element={<TermsOfService />}
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/support" element={<Support />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

App.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default App;
