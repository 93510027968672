import React from "react";
import styles from "./support.module.css";
import Box from "@mui/material/Box";

class Support extends React.Component {
  render() {
    document.body.style.background = "#ffffff";
    return (
      <Box sx={{ p: { xs: 1, sm: 6 } }}>
        <div className={styles.title}>Support</div>
        <div className={styles.main}>
          <strong>
            If you have any issues, questions or concerns, please email contact@appanysoftware.com
          </strong>
        </div>
      </Box>
    );
  }
}

export default Support;
